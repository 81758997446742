<template>
  <section class="my--order-header">
    <!--    <div class="category&#45;&#45;container hide--in-mobile">-->
    <!--      <router-link class="btn btn-primary&#45;&#45;outline" to="/dashboard/mylisting">-->
    <!--        {{ $t('dashboard.myListing') }}-->
    <!--      </router-link>-->
    <!--      <router-link class="btn btn-primary" to="/dashboard/mybooking">-->
    <!--        {{ $t('dashboard.myBooking') }}-->
    <!--      </router-link>-->
    <!--      <router-link class="btn btn-primary&#45;&#45;outline" to="/dashboard/favorites">-->
    <!--        {{ $t('dashboard.favorite') }}-->
    <!--      </router-link>-->
    <!--    </div>-->
    <div class="dashboard--search-container">
      <!--      <div class="dropdown&#45;&#45;wrapper">-->
      <!--        <v-select-->
      <!--          :value="activeSortBy"-->
      <!--          @input="inputSortByHandler"-->
      <!--          :hint="`${$t('general.filter.sort')} ${$t(`general.filter.${activeSortBy.name}`)}`"-->
      <!--          :items="sortBys"-->
      <!--          :item-text="sortByLabel"-->
      <!--          item-value="name"-->
      <!--          class="basic&#45;&#45;dropdown"-->
      <!--          persistent-hint-->
      <!--          return-object-->
      <!--          single-line-->
      <!--        />-->
      <!--      </div>-->
      <search-order />
    </div>
  </section>
</template>

<script>
import SearchOrder from '@/components/order/search-order';

export default {
  name: 'orders-header',
  components: {
    SearchOrder,
  },
  computed: {},
  methods: {},
};
</script>
