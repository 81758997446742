<template>
  <div class="search--order-wrapper">
    <div style="position: relative">
      <v-text-field
        :label="$t('order.search')"
        :placeholder="$t('order.placeholder.search')"
        outlined
        :hide-details="true"
        v-model="keyword"
        name="keyword"
        class="basic--input"
      ></v-text-field>
      <div class="search--button" @click="getOrders">
        <v-icon>mdi-magnify</v-icon>
      </div>
    </div>
    <div v-show="keyword" class="reset--search">
      <button type="button" @click="resetSearch">
        <i class="ion-close"></i>
        <span class="reset--text">{{ $t('order.resetSearch') }}</span>
      </button>
    </div>
  </div>
</template>

<script>
import debounce from 'lodash/debounce';
export default {
  name: 'search-order',
  components: {},
  data: () => ({
    optionsLimit: 0,
  }),
  computed: {
    keyword: {
      get() {
        return this.$store.state.order.keyword;
      },
      set(value) {
        this.$store.commit('order/SET_KEYWORD', value);
        this.getOrders();
      },
    },
  },
  methods: {
    resetSearch() {
      this.$store.commit('order/SET_KEYWORD', null);
      this.$router.replace({ query: null });
      this.$store.dispatch('order/getAllOrders', 1);
    },
    getOrders: debounce(function () {
      this.$router.replace({ query: { q: this.keyword } });
      this.$store.dispatch('order/getAllOrders', 1);
    }, 500),
  },
  async mounted() {
    await this.$store.dispatch('order/restoreInitiateState');
    let q = this.$route.query.q;
    this.$store.commit('order/SET_KEYWORD', q);
    let page = this.$route.query.page;
    await this.$store.dispatch('order/getAllOrders', page);
  },
};
</script>

<style scoped></style>
