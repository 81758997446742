<template>
  <div class="countdown--wrapper">
    <div class="mr-2">{{ $t('order.payBefore') }}:</div>
    <vue-countdown
      class="countdown"
      :time="paymentDueDiff"
      :transform="transformSlotProps"
      :interval="1000"
      v-slot="{ hours, minutes, seconds }"
    >
      {{ hours }}:{{ minutes }}:{{ seconds }}
    </vue-countdown>
  </div>
</template>

<script>
import VueCountdown from '@chenfengyuan/vue-countdown';
export default {
  name: 'countdown',
  props: ['paymentDueDiff'],
  components: {
    VueCountdown,
  },
  methods: {
    transformSlotProps(props) {
      const formattedProps = {};

      Object.entries(props).forEach(([key, value]) => {
        formattedProps[key] = value < 10 ? `0${value}` : String(value);
      });

      return formattedProps;
    },
  },
};
</script>

<style scoped></style>
