<template>
  <default-layout :breadcrumbs="breadcrumbs">
    <div class="container mb-4">
      <orders-header />
      <div class="my--order mt-2">
        <v-tabs center-active v-if="categories && categories.length > 0">
          <v-tab
            :href="`#tab-${index}`"
            v-for="(category, index) in categories"
            :key="`category-title-${index}`"
            @click="changeCategory(category)"
          >
            {{ category.name }}
          </v-tab>
          <v-tab-item
            :value="`tab-${index}`"
            v-for="(category, index) in categories"
            :key="`category-content-${index}`"
          >
            <SectionLoader :show="loading" />
            <div v-for="order in orders" :key="order.uuid" class="order--item">
              <card-order :order="order" />
            </div>
            <pagination
              :push-state="false"
              :meta="ordersMeta.pagination"
              @changePage="changePage"
              :maxVisibleButtons="5"
              v-if="ordersMeta"
            />
          </v-tab-item>
        </v-tabs>
      </div>
    </div>
  </default-layout>
</template>

<script>
import DefaultLayout from '@/layouts/default';
import DefaultHeadMixin from '@/mixins/defaultHead';
import OrdersHeader from '@/components/order/orders-header';
import SectionLoader from '@/components/content-loading/section-loading';
import CardOrder from '@/components/order/card-order';
import Pagination from '@/components/utils/fractal-pagination';
import { mapState } from 'vuex';

export default {
  name: 'index',
  mixins: [DefaultHeadMixin],
  components: {
    DefaultLayout,
    OrdersHeader,
    SectionLoader,
    CardOrder,
    Pagination,
  },
  computed: {
    ...mapState({
      loading: (state) => state.order.loading,
      categories: (state) => state.order.categories,
      orders: (state) => state.order.orders,
      ordersMeta: (state) => state.order.ordersMeta,
    }),
    breadcrumbs() {
      const currentName = this.$t('home.sidebarMenu.myOrder');
      return [
        {
          text: this.$t('breadcrumb.homepage'),
          to: '/',
        },
        {
          text: currentName,
          to: this.$router.currentRoute.path,
        },
      ];
    },
  },
  methods: {
    async changeCategory(category) {
      this.$store.commit('order/SET_CATEGORY', category);
      this.$store.commit('order/SET_KEYWORD', null);
      this.$router.replace({ query: null });
      await this.$store.dispatch('order/getAllOrders', 1);
    },
    async changePage(page) {
      await this.$store.dispatch('order/getAllOrders', page);
    },
  },
  async mounted() {},
};
</script>
